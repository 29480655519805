<template>
  <div class="max-w-7xl mx-auto">
    <div class="sm:pt-6 sm:pb-24">
      <header class="hidden sm:flex justify-between">
        <div>
          <slot name="breadcrumbs" />
        </div>
      </header>

      <div class="md:grid md:grid-cols-6 flex flex-col mt-2">

        <!-- Left Side -->
        <div class="md:col-span-2">
          <slot name="slider" />
        </div>

        <!-- Right Side -->
        <div class="px-6 flex flex-col md:col-span-2 text-primary">
          <slot name="brandImage" />

          <slot name="title" />
          <slot name="modelSku" />
          <slot name="reviewsInfo" />

          <div class="flex justify-between items-center py-2 border-b my-2">
            <div class="flex items-center space-x-1.5 ">
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Etiket - Ses')">
                <NuxtImg :src="getAttributeImage('Etiket - Ses')" class="w-6 h-6" :alt="getAttributeText('Etiket - Ses')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Etiket - Ses')}}</span>
              </div>
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Etiket - Yakıt')">
                <NuxtImg :src="getAttributeImage('Etiket - Yakıt')" class="w-6 h-6" :alt="getAttributeText('Etiket - Yakıt')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Etiket - Yakıt')}}</span>
              </div>
              <div class="flex items-center space-x-1" v-if="getAttributeImage('Yol Tutuş')">
                <NuxtImg :src="getAttributeImage('Yol Tutuş')" class="w-6 h-6" :alt="getAttributeText('Yol Tutuş')" />
                <span class="text-sm font-bold text-gray-600">{{getAttributeItemText('Yol Tutuş')}}</span>
              </div>
            </div>

            <!-- Mevsim -->
            <div v-if="getAttributeItemImage('Mevsim')">
              <NuxtImg :src="getAttributeItemImage('Mevsim')" class="w-6 h-6" :alt="getAttributeItemText('Mevsim')" />
            </div>
          </div>

          <!-- Ürün Özellikleri -->
          <ul role="list" class="divide-y divide-gray-100" v-if="product?.features.length">
            <template v-for="item in filteredFeatures">
              <li :key="item.attributeId" class="flex gap-x-1 py-1" v-if="item.attributeItemName">
                <div class="min-w-0">
                  <p class="text-sm font-bold text-gray-900">{{ item.attributeName }}</p>
                  <p class="truncate text-sm text-gray-500">{{ item.attributeItemName }}</p>
                </div>
              </li>
            </template>
          </ul>

        </div>

        <div class="px-4 py-4 flex flex-col md:col-span-2 border bg-gray-100">

          <div class="space-y-4 mb-5">
            <!-- Toptan Fiyatı -->
            <slot name="wholesalePrice" />

            <!-- B2C Fiyatı -->
            <slot name="productPrice" />

            <!-- Kampanya Bilgisi -->
            <slot name="discountLabel" />

            <!-- Son Kalan Ürünler -->
            <slot name="productCriticalQuantity" />
          </div>

          <!-- Renk Seçenekleri -->
          <slot name="colorOptions" />

          <!-- Beden Seçenekleri -->
          <slot name="sizeOptions" />

          <!-- Taksit Bilgisi - X TL'den Başlayan Fiyatlarla -->
          <slot name="installmentInfo" />

          <div class="mt-6">
            <!-- Birim -->
            <slot name="unitInfo" />

            <!-- Sepete Ekle, Favorilere Ekle vb -->
            <slot name="shopButtons" />
          </div>

          <!-- Öne Çıkan Site Özellikleri -->
          <slot name="highlights" />
        </div>
      </div>

      <!-- Ürün Özellikleri -->
      <slot name="metaInformations" />

      <!-- Benzer Ürünler -->
      <slot name="relatedProducts" />
    </div>
  </div>
</template>

<script setup>
const {product} = await useProduct()

const features = computed(() => product.value?.features);

const filteredFeatures = computed(() => features.value.filter((i) => !inArray(i.queryName, ['etiket-yakit', 'etiket-ses', 'mevsim'])))

const getAttributeImage = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeImage
}

const getAttributeText = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeName
}

const getAttributeItemImage = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeItemImage
}

const getAttributeItemText = (attributeName) => {
  return features.value.filter((i) => i.attributeName === attributeName)[0]?.attributeItemName
}

</script>

